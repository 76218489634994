import request from '@/utils/request'

const fapiaoDetailApi = {
  trade_fapiao_detail_list: '/trade/fapiao_detail/',
  trade_fapiao_detail_create: '/trade/fapiao_detail/',
  trade_fapiao_detail_update: '/trade/fapiao_detail/',
  trade_fapiao_detail_delete: '/trade/fapiao_detail/'
}

/**
 * 列表
 */
export function fapiao_detail_list (parameter) {
  return request({
    url: fapiaoDetailApi.trade_fapiao_detail_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function fapiao_detail_create (parameter) {
  return request({
    url: fapiaoDetailApi.trade_fapiao_detail_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function fapiao_detail_update (parameter, corporation_id) {
  return request({
    url: fapiaoDetailApi.trade_fapiao_detail_update + corporation_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function fapiao_detail_delete (corporation_id) {
  return request({
    url: fapiaoDetailApi.trade_fapiao_detail_delete + corporation_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
